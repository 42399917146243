.navigation {
  span {
    margin-top: 10px;
    color: #ffffff;
    display: inline-block;
  }

  .logoImg {
    margin-top: -3px;
  }

  .ui[class*="right floated"].header {
    margin-top: 0;
    margin-bottom: 0;
  }
}