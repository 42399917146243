.not-found {
  text-align: center;
  margin-top: 30%;
}
.not-found h1,
.not-found h2 {
  color: red;
}
.not-found span {
  text-decoration: underline;
}