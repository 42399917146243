.synonym {
  h1 {
    text-align: center;
  }


  .synonymTable{
    padding: 50px;
  }

  .ui.table thead tr:first-child > th {
    position: sticky !important;
    top: 0;
    z-index: 2;
  }
}
