.not-found {
  text-align: center;
  margin-top: 30%;

  h1,
  h2 {
    color: red;
  }

  span {
    text-decoration: underline;
  }

}