@import "./variables";

.app {
  color: #252525;
  display: block;
  line-height: 22px;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: $black;

  &:visited {
    color: $black;
  }
}