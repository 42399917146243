.navigation span {
  margin-top: 10px;
  color: #ffffff;
  display: inline-block;
}
.navigation .logoImg {
  margin-top: -3px;
}
.navigation .ui[class*="right floated"].header {
  margin-top: 0;
  margin-bottom: 0;
}