.synonym h1 {
  text-align: center;
}
.synonym .synonymTable {
  padding: 50px;
}
.synonym .ui.table thead tr:first-child > th {
  position: sticky !important;
  top: 0;
  z-index: 2;
}