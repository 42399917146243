/* ---------------------------------------------
               Colors
 --------------------------------------------- */
.app {
  color: #252525;
  display: block;
  line-height: 22px;
  font-size: 16px;
}

a {
  text-decoration: none;
  color: #000000;
}
a:visited {
  color: #000000;
}